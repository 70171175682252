import * as React from "react"
import { Link, HeadFC } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const tableStyles = {
  height: 301,
}

const BannerPageOne = () => {
  return (
    <>
      <a id="banner-1" href="/">
        <StaticImage src="../assets/images/landing/banner-1.png" alt={"banner image"} />
      </a>
    </>
  )
}

export default BannerPageOne

export const Head: HeadFC = () => <title>Banner 4 (Image)</title>